import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import axiosAuth from "../axios/Interceptors";
import AppContext from "../Context/Context";
import ElapsedTime from "./ElapsedTime";
import { useGlobalState } from "../Context/GlobalStateContext";

type Props = {
  MGWorkspaceID: any;
  EventDetails: any;
  HandleChildClick: Function;
};
export default function TimerManagement(props: Props) {
  const globalState = useGlobalState();
  const [timerObject, setTimerObject] = useState({
    mgeventid: 0,
    siteuserid: 0,
    name: 0,
    startdatetime: 0,
    enddatetime: 0,
    durationinminutes: 0,
    wastageminutes: 0,
    wastagecomments: "",
    staffcomments: "",
    managementcomments: "",
    ismanualentry: 0,
    manualentrytime: 0,
    manuallyenteredbyid: 0,
    ptorderby: 0,
    objectstatus: 0,
  });

  console.log(props.EventDetails);
  function MGEventSetEventStatusDatandManageMGTimeLog(mgeventstatusid: any) {
    let object: any = {};
    object.mgeventstatusid = mgeventstatusid;
    object.mgeventid = props.EventDetails.MGEventID;
    object.siteuserid = props.EventDetails.SiteUserID;
    object.mgtimelogid = props.EventDetails.MGTimeLogID;
    object.wastageminutes = timerObject.wastageminutes;
    object.wastagecomments = timerObject.wastagecomments; // any waste, popup
    axiosAuth
      .post(
        AppContext.AppBaseURL +
          "api/MGTimeLog/MGEventSetEventStatusDatandManageMGTimeLog",
        object
      )
      .then((response) => {
        if (response.data.Status == 0) {
          let result = response.data.Data;
          if (result.length > 0 && result[0].length > 0) {
            globalState.setNotificationObject({
              ShowPopup: true,
              Heading: "Alert",
              Message: result[0][0]["Message"],
            });
            // alert(result[0][0]["Message"]);
          }
        }
        props.HandleChildClick(3, {});
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function saveTimeLog() {
    let object: any = {};
    object.mgeventstausid = 0;
    object.mgeventid = timerObject.mgeventid; // from props
    object.siteuserid = timerObject.siteuserid; // from props
    object.name = timerObject.name;
    object.startdatetime = timerObject.startdatetime;
    object.enddatetime = timerObject.enddatetime;
    object.durationinminutes = timerObject.durationinminutes;
    object.wastageminutes = timerObject.wastageminutes;
    object.wastagecomments = timerObject.wastagecomments; // any waste, popup
    object.staffcomments = timerObject.staffcomments;
    object.managementcomments = timerObject.managementcomments;
    object.ismanualentry = timerObject.ismanualentry;
    object.manualentrytime = timerObject.manualentrytime;
    object.manuallyenteredbyid = timerObject.manuallyenteredbyid;
    object.ptorderby = timerObject.ptorderby;
    object.objectstatus = timerObject.objectstatus;
    axiosAuth
      .post(AppContext.AppBaseURL + "api/MGTimeLog/MGTimeLogSaveCustom", object)
      .then((response) => {
        if (response.data.Status === 1) {
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  return (
    <>
      <div className="flex-d flex-jc run-timer">
        {props.EventDetails.MGEventStatusID == 16 &&
        props.EventDetails.LogStartDateTime != "" ? (
          <ElapsedTime
            startDateTime={AppContext.ConvertDateTimeToLocal(
              props.EventDetails.LogStartDateTime
            )}
          />
        ) : (
          <>00:00:00</>
        )}
      </div>

      <div className="flex-d flex-ac flex-jc flex-gap10">
        {props.EventDetails.MGEventStatusID != 16 && (
          <button
            title="Play"
            className="play-pause-btns btn-play"
            onClick={() => MGEventSetEventStatusDatandManageMGTimeLog(16)}
          >
            <img src="/images/play.svg" alt="img" width={15} height={15} />
          </button>
        )}
        {props.EventDetails.MGEventStatusID == 16 &&
          props.EventDetails.LogStartDateTime != "" && (
            <>
              <button
                title="Pause"
                className="play-pause-btns btn-pause"
                onClick={() => MGEventSetEventStatusDatandManageMGTimeLog(17)}
              >
                <img src="/images/pause.svg" alt="img" width={15} height={15} />
              </button>
              <button
                title="Stop"
                className="play-pause-btns btn-stop"
                onClick={() => MGEventSetEventStatusDatandManageMGTimeLog(4)}
              >
                <img src="/images/stop.svg" alt="img" width={15} height={15} />
              </button>
            </>
          )}

        {/* {props.EventDetails.MGEventStatusID == 16 &&
          props.EventDetails.LogStartDateTime == "" && (
            <button
              title="Stop"
              className="play-pause-btns btn-stop"
              onClick={() => MGEventSetEventStatusDatandManageMGTimeLog(4)}
            >
              <img src="/images/stop.svg" alt="img" width={15} height={15} />
            </button>
          )} */}
      </div>
    </>
  );
}
