import React from "react";
import AppContext from "../Context/Context";
import { useState, useEffect, useRef } from "react";
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation";

import { useGlobalState } from "../Context/GlobalStateContext";
import axiosAuth from "../axios/Interceptors";
import TableGridView from "../App/DynamicGridView";
import { getEndOfMonth, getStartOfMonth } from "../Shared/Utility";
function TeamStatus() {
  const globalState = useGlobalState();
  const [screenID, setScreenID] = useState(0);

  const [showAnimation, setShowAnimation] = useState(false);
  const [showReportGrid, setShowReportGrid] = useState(false);
  const [reportGridData, setReportGridData] = useState<any>([]);

  const [filtersDropdownData, setFiltersDropdownData] = useState({
    ReportSectionData: [],
    ReportSectionButtonData: [],
    ProjectData: [],
    UserData: [],
  });

  const [selectedFiltersDropdownObject, setSelectedFiltersDropdownObject] =
    useState({
      projectid: 0,
      listid: 0,
      from: getStartOfMonth(),
      to: getEndOfMonth(),
      userid: 0,
      dateformate: 1,
    });

  useEffect(() => {
    downloadJLRReport(4);
  }, []);

  function downloadJLRReport(reportNumber: any) {
    setShowAnimation(true);
    let object = {} as any;
    object.reportnumber = reportNumber;
    object.reporttype = 1;
    object.projectid = selectedFiltersDropdownObject.projectid;
    object.listid = selectedFiltersDropdownObject.listid;
    object.from = selectedFiltersDropdownObject.from;
    object.to = selectedFiltersDropdownObject.to;
    object.userid = selectedFiltersDropdownObject.userid;
    setReportGridData([]);
    axiosAuth
      .post(AppContext.AppBaseURL + "api/MGReporting/DownloadReport", object)
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          if (l_response && l_response[0] && l_response[0].Table) {
            setReportGridData(l_response[0].Table);
          }
          setShowReportGrid(true);

          console.log(l_response);
        }
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  return (
    <>
      {showAnimation && <LoadingAnimation />}
      <section className="msg--body">
        <div className="msg--body__lt ">
          <div className="msg--body__lt__head flex-d flex-ac">
            <div className="flex-d flex-ac">Team Status</div>
          </div>
          <div className="grid-d fr-1111 p-all10 gap20">
            {reportGridData &&
              reportGridData.map((Item: any, index: any) => (
                <React.Fragment key={index}>
                  <div className="card-i">
                    <span
                      className={
                        Item["Status"] == "Currently Working"
                          ? "card-status strip-g"
                          : "card-status strip-y"
                      }
                    ></span>
                    <div className="flex-d flex-ac gap10">
                      <div
                        className={
                          Item["Status"] == "Currently Working"
                            ? "flex-d flex-ac flex-jc p-pic br-green"
                            : "flex-d flex-ac flex-jc p-pic br-yellow"
                        }
                      >
                        <img
                          src="images/profile.jpg"
                          alt="Profile Picture"
                          width={70}
                          height={70}
                        />
                      </div>
                      <div className="flex-d flex-jsb pp-wid flex-as">
                        <div className="grid-d">
                          <span className="user-name">
                            {Item["Team Member"]}
                          </span>
                          <span className="user-desg">Project Manager</span>
                        </div>
                        <div className="time-rec flex-d flex-ac gap5">
                          <span className="rec-lbl">Today:</span>
                          <span className="rec-val">
                            {Item["Time Logged Today"]}
                          </span>
                          <img
                            src="images/arrow.svg"
                            alt="img"
                            width={8}
                            height={6}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="grid-d tasks-details gap20 mt--20">
                      <div className="flex-d flex-ac flex-gap10">
                        <span
                          className="task-c-title"
                          style={{ background: "#a29ae7" }}
                        >
                          Current
                        </span>
                        <span className="task-title">{Item["Task Name"]}</span>
                      </div>
                      <div className="flex-d flex-ac flex-gap10">
                        <span
                          title={Item["Project"]}
                          className="task-c-title"
                          style={{ background: "#eba472" }}
                        >
                          Previous
                        </span>
                        <span className="task-title">{Item["Task Name"]}</span>
                      </div>
                      <div className="flex-d flex-ac flex-gap10">
                        <span
                          className="task-c-title strip-r"
                          style={{ background: "#f191ac" }}
                        >
                          Next
                        </span>
                        <span className="task-title">{Item["Task Name"]}</span>
                      </div>
                    </div>
                  </div>
                  {/* <span>
                    Time Logged Yesterday: {Item["Time Logged Yesterday"]} |{" "}
                  </span>
                  <span>
                    Time Logged Last 7 Days: {Item["Time Logged Last 7 Days"]}
                  </span> */}
                  {/* <span>Task Name: {Item["Task Name"]} | </span>
                    Status: {Item["Status"]} |{" "} */}
                </React.Fragment>
              ))}
          </div>
        </div>
      </section>
    </>
  );
}

export default TeamStatus;
