import { Link } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import AppContext from "../Context/Context";
import Notification from "./Notification";
import { useGlobalState } from "../Context/GlobalStateContext";
import Support from "./Support";
export default function Header() {
  const globalState = useGlobalState();
  const [Showdropdown, SetShowdropdown] = useState(false);
  const [Refresh, SetRefresh] = useState(false);
  useEffect(() => {
    AppContext.GetJWTAndDecode();
    SetRefresh(!Refresh);
  }, []);

  const ShowHidedropdown = () => {
    SetShowdropdown(!Showdropdown);
  };

  const logoutRef = useRef<any>(null);
  useEffect(() => {
    // Function to handle outside clicks
    const handleClickOutside = (event: any) => {
      // Check if the click target is outside the component's DOM node

      if (logoutRef.current && !logoutRef.current.contains(event.target)) {
        SetShowdropdown(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const LogOutUser = () => {
    AppContext.SetCookie("Token", "", 0);
    AppContext.SetCookie("RefreshToken", "", 0);
    SetShowdropdown(!Showdropdown);
    AppContext.LogInUserName = "";
    window.location = ("https://auth" +
      AppContext.LiveLinkPostFix +
      ".manyge.com/login") as any;
  };

  function NotificationCallBack() {
    globalState.setNotificationObject({
      ShowPopup: false,
      Heading: "",
      Message: "",
    });
  }

  return (
    <>
      <Notification
        NotificationObject={globalState.NotificationObject}
        NotificationCallBack={NotificationCallBack}
      />

      <header className="header">
        <div className="header--start">
          <figure className="header-start__logo">
            <img
              src="/images/logo.png"
              className="logo--dark"
              alt="logo"
              width="84px"
              height="20px"
            />
            <img
              src="/images/logolight.png"
              className="logo--light"
              alt="logo"
              width="84px"
              height="20px"
            />
          </figure>
        </div>
        <div className="header--end" ref={logoutRef}>
          <Link to={"/supporttickets"} title="Support Ticket">
            <img src="/images/ticket.svg" width="24px" height="24" />
          </Link>
          <div className="header--end__user">
            <a className="user--img" onClick={ShowHidedropdown}>
              {/* <img
                src="/images/user.png"
                alt="user"
                width="30px"
                height="30px"
              /> */}
              <span>{AppContext.GetInitials(AppContext.LogInUserName)}</span>
            </a>

            {Showdropdown ? (
              <div className="header--end__links">
                <a href="/settings">Settings</a>
                <a onClick={LogOutUser}>Logout</a>
              </div>
            ) : (
              <span></span>
            )}
          </div>
        </div>
      </header>
      <menu className="menu">
        <div className="menu--link" title="Dashboard">
          <Link to={"/dashboard"}>
            <img
              src="/images/dashboard.png"
              alt="Dashboard"
              width="18px"
              height="16px"
            />
          </Link>
        </div>
        <div className="menu--link" title="Task">
          <Link to={"/tasks"}>
            <img
              src="/images/task.svg"
              alt="Tasks"
              width="16px"
              height="20px"
            />
          </Link>
        </div>
        <div className="menu--link" title="Messaging">
          <Link to={"/comm"}>
            <img
              src="/images/message.png"
              alt="Dashboard"
              width="16px"
              height="16px"
            />
          </Link>
        </div>
        {/* {AppContext.LogInUserRole == "Admin" && */}
        <div className="menu--link sub-menu" title="Admin">
          <a>
            <img
              src="/images/admin.svg"
              alt="Dashboard"
              width="22px"
              height="22px"
            />
          </a>
          <div className="sub-menu-links">
            <Link to={"/projectlist"}>Projects List</Link>
            <Link to={"/userlist"}>User List</Link>
            <Link to={"/reporting"}>Reporting</Link>
            <Link to={"/tasksmanagement"}>Tasks Management</Link>
            <Link to={"/supporttickets"}>Support Tickets</Link>
          </div>
        </div>

        {/* } */}
      </menu>
    </>
  );
}
