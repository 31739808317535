import React, { useState, useRef, useEffect, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import AppContext from "../Context/Context";
import axiosAuth from "../axios/Interceptors";
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation";

type DragDropProps = {
  ParentFeatures: any;
  DragDropCallback: any;
  TriggerChild: any;
};

export default function DragDropHandler(props: DragDropProps) {
  const [LoadingState, SetLoadingState] = useState(false);
  const [files, setFiles] = useState([]);
  const [MaxAllowedFiles, SetMaxAllowedFiles] = useState(
    props.ParentFeatures.MaxAllowedFiles
  );
  const [IsDisable, SetIsDisable] = useState(false);
  const [PTUploadedFiles, SetPTUploadedFiles] = useState(
    props.ParentFeatures.PTUploadedFiles
  );

  const FilesRef: any = useRef(null);
  FilesRef.current = files;

  const PTUploadedFilesRef = useRef<any>(null);
  PTUploadedFilesRef.current = PTUploadedFiles;

  const [PTUploadedFilesPreview, SetPTUploadedFilesPreview] = useState<any>([]);
  const PTUploadedFilesRefPreview = useRef<any>(null);
  PTUploadedFilesRefPreview.current = PTUploadedFilesPreview;

  useEffect(() => {
    PTUploadedFilesRefPreview.current = [];
    SetPTUploadedFilesPreview([]);
    SetPTUploadedFiles([]);
    props.DragDropCallback([]);
  }, [props.TriggerChild]);

  useEffect(() => {
    debugger;
    SetPTUploadedFiles(props.ParentFeatures.PTUploadedFiles);
    PTUploadedFilesRef.current = props.ParentFeatures.PTUploadedFiles;
    if (
      props.ParentFeatures.PTUploadedFiles &&
      props.ParentFeatures.PTUploadedFiles.length > 0
    ) {
      UploadFiles();
    } else {
      PTUploadedFilesRefPreview.current = [];
      SetPTUploadedFilesPreview([]);
    }
  }, [props.ParentFeatures.PTUploadedFiles]);

  const RemoveFile = (index: any) => {
    const newFiles = [...PTUploadedFilesRefPreview.current];
    newFiles.splice(index, 1);
    SetPTUploadedFilesPreview(newFiles);
    props.DragDropCallback(newFiles);
  };

  async function UploadFiles() {
    // PTUploadedFilesRefPreview.current = [];
    // SetPTUploadedFilesPreview([]);

    for (let i = 0; i < PTUploadedFilesRef.current.length; i++) {
      debugger
      if (!PTUploadedFilesRef.current[i].ObjectID) {
        const url = AppContext.FilesAPI + "FileUploader/UploadFile";
        let FileNewName = PTUploadedFilesRef.current[i].name; ///AppContext.GenerateUniqueGUID() + "_" + FilesRef.current[i].name;
        let FileNameWithoutSpaces = FileNewName.replace(/\s/g, "");
        let FilePath =
          "Uploads/" +
          props.ParentFeatures.FolderName +
          "/" +
          FileNameWithoutSpaces;
        let OtherParams =
          "1," +
          props.ParentFeatures.ObjectType +
          "," +
          props.ParentFeatures.ObjectID +
          ",3";
        if (
          props.ParentFeatures.OtherParams &&
          props.ParentFeatures.OtherParams !== ""
        ) {
          OtherParams += "," + props.ParentFeatures.OtherParams;
        }
        let formData = new FormData();
        formData.append(
          "file",
          PTUploadedFilesRef.current[i],
          FileNameWithoutSpaces
        );
        formData.append("UploadType", props.ParentFeatures.UploadType);
        formData.append("FolderName", props.ParentFeatures.FolderName);
        formData.append("OtherParams", OtherParams);
        SetLoadingState(true);
        await axiosAuth.post(url, formData).then((response) => {
          if (response.data.Status === 1) {
            if (response.data.Data[0].Status === 1) {
              let object: any = {};
              object.Name = response.data.Data[0].FileName;
              object.FileURL = response.data.Data[0].FilePath;
              object.FilePathFull = response.data.Data[0].FilePathFull;
              object.SysObjID = response.data.Data[0].SysObjID;
              object.ObjectID = props.ParentFeatures.ObjectID;
              object.FileURLDisplay = AppContext.GetFileIconToDisplay(
                response.data.Data[0].FilePathFull
              );

              // const l_PTUploadedFilesRef = [...PTUploadedFilesRef.current];
              // l_PTUploadedFilesRef.push(object);
              // SetPTUploadedFiles(l_PTUploadedFilesRef);

              const l_PTUploadedFilesRef = [
                ...PTUploadedFilesRefPreview.current,
              ];
              l_PTUploadedFilesRef.push(object);
              PTUploadedFilesRefPreview.current = l_PTUploadedFilesRef;
              SetPTUploadedFilesPreview(l_PTUploadedFilesRef);
              props.DragDropCallback(l_PTUploadedFilesRef);
            }
          }
          SetLoadingState(false);
        });
      }
    }
  }
  const Files = PTUploadedFilesRefPreview.current.map(
    (file: any, index: any) => (
      <div className="remove--image" key={index}>
        <img
          key={file.Name}
          src={file.FileURLDisplay}
          alt="image"
          style={{ width: "200px", height: "200px" }}
          className="upld-img"
        />
        <a onClick={() => RemoveFile(index)}>
          <img
            className=""
            src="/images/delete.png"
            alt="edit"
            width="15"
            height="15"
            style={{ width: "15px", height: "15px" }}
          />
        </a>
      </div>
    )
  );

  return (
    <>
      {LoadingState && <LoadingAnimation />}
      <div className="flex-d flex-gap10 wrap-flex mt--10">{Files}</div>
    </>
  );
}
