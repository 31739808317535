import { Routes, Route } from "react-router-dom";
//import "react-notifications/lib/notifications.css";
//import { NotificationContainer } from "react-notifications";
import React, { useEffect, useState } from "react";
import AppContext from "./components/Context/Context";
import Header from "./components/App/Header";
import Comm from "./components/Pages/Comm";
import MyWorkSpaces from "./components/Pages/MyWorkSpaces";
import Dashboard from "./components/Pages/Dashboard";
import ParentDragDrop from "./components/Pages/ParentDragDrop";
import Settings from "./components/Pages/Settings";
import TasksKanbanView from "./components/Pages/Tasks/TasksKanbanView";
import { GlobalStateProvider } from "./components/Context/GlobalStateContext";
import ProjectList from "./components/Pages/Tasks/ProjectList";
import Reporting from "./components/Pages/Reporting";
import UserList from "./components/Pages/Tasks/UserList";
import RequireAuth from "./components/Auth/RequireAuth";
import SupportTickets from "./components/Pages/SupportTickets";
import TasksManagement from "./components/Pages/TasksManagement";
import TeamStatus from "./components/Pages/TeamStatus";

function App() {
  const [WhiteTheme, SetWhiteTheme] = useState(false);

  const EnableWhiteTheme = () => {
    SetWhiteTheme(!WhiteTheme);
  };

  useEffect(() => {
    if (WhiteTheme) {
      document.body.classList.add("white--theme");
    } else {
      document.body.classList.remove("white--theme");
    }
  }, [WhiteTheme]);

  useEffect(() => {
    let IsloggedIn = AppContext.IsUserLoggedIn(true);
    if (!IsloggedIn) {
      // window.location = 'https://auth'+AppContext.LiveLinkPostFix +'.manyge.com/login'
    }
  }, []);

  return (
    <main>
      {/* <NotificationContainer /> */}
      <GlobalStateProvider>
        <div className="App--main">
          <a onClick={EnableWhiteTheme} className="btn--theme">
            {WhiteTheme === true && (
              <img
                src="/images/dark.svg"
                alt="img"
                width="20px"
                height="20px"
              />
            )}
            {WhiteTheme === false && (
              <img
                src="/images/light.svg"
                alt="img"
                width="20px"
                height="20px"
              />
            )}
          </a>
          <Header />
          <div className="Body--Main">
            <Routes>
              <Route
                path=""
                element={
                  <RequireAuth>
                    <Comm />
                  </RequireAuth>
                }
              />
              <Route
                path="workspace"
                element={
                  <RequireAuth>
                    <MyWorkSpaces />
                  </RequireAuth>
                }
              />
              <Route
                path="dashboard"
                element={
                  <RequireAuth>
                    <Dashboard />
                  </RequireAuth>
                }
              />
              <Route
                path="dashboard/:workspaceid"
                element={
                  <RequireAuth>
                    <Dashboard />
                  </RequireAuth>
                }
              />
              <Route
                path="comm"
                element={
                  <RequireAuth>
                    <Comm />
                  </RequireAuth>
                }
              />
              <Route
                path="comm/:workspaceid"
                element={
                  <RequireAuth>
                    <Comm />
                  </RequireAuth>
                }
              />
              <Route
                path="comm/:workspaceid/:chennelid"
                element={
                  <RequireAuth>
                    <Comm />
                  </RequireAuth>
                }
              />
              <Route
                path="testdragdrop"
                element={
                  <RequireAuth>
                    <ParentDragDrop />
                  </RequireAuth>
                }
              />
              <Route
                path="settings"
                element={
                  <RequireAuth>
                    <Settings />
                  </RequireAuth>
                }
              />
              <Route
                path="tasks"
                element={
                  <RequireAuth>
                    <TasksKanbanView />
                  </RequireAuth>
                }
              />
              <Route
                path="projectlist"
                element={
                  <RequireAuth>
                    <ProjectList />
                  </RequireAuth>
                }
              />
              <Route
                path="userlist"
                element={
                  <RequireAuth>
                    <UserList />
                  </RequireAuth>
                }
              />
              <Route
                path="reporting"
                element={
                  <RequireAuth>
                    <Reporting />
                  </RequireAuth>
                }
              />

              <Route
                path="supporttickets"
                element={
                  <RequireAuth>
                    <SupportTickets />
                  </RequireAuth>
                }
              />

              <Route
                path="tasksmanagement"
                element={
                  <RequireAuth>
                    <TasksManagement />
                  </RequireAuth>
                }
              />
                <Route
                path="teamstatus"
                element={
                  <RequireAuth>
                    <TeamStatus />
                  </RequireAuth>
                }
              />
            </Routes>
          </div>
        </div>
      </GlobalStateProvider>
    </main>
  );
}

export default App;
