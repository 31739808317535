import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import AppContext from "../Context/Context";
import axiosAuth from "../axios/Interceptors";
import { paste } from "@testing-library/user-event/dist/paste";
import { send } from "process";
function Dashboard() {
  const params = useParams();
  const workspaceid = params.workspaceid;
  const Navigate = useNavigate();

  const [MySelectedWorkspace, SetMySelectedWorkspace] = useState<any>({});
  const [MyWorkSpacesData, SetMyWorkSpacesData] = useState([]);
  const [text, setText] = useState<string>('');
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    LoadDashboardDataInit();
  }, []);

  function LoadDashboardDataInit() {
    let SelectedWorkSpace =
      AppContext.GetLocalStorageObject("SelectedWorkSpace");
    GetMyWorkSpaces();
    // if (SelectedWorkSpace && SelectedWorkSpace !== null) {
    //   GetMyWorkSpaces();
    // } else {
    //   Navigate("/workspace");
    // }
  }

  function GetMyWorkSpaces() {
    let object = {};
    axiosAuth
      .post(
        "https://authapi.manyge.com/api/MGWorkspace/GetMyWorkSpaces",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          var l_GridData = response.data.Data[0];
          AppContext.SetLocalStorageObject("WorkSpaceList", l_GridData);
          SetMyWorkSpacesData(l_GridData);
          let SelectedWorkSpaceid = "";
          for (let i = 0; i < l_GridData.length; i++) {
            if (l_GridData[i].Selected) {
              SetMySelectedWorkspace(l_GridData[i]);
              AppContext.SetLocalStorageObject(
                "SelectedWorkSpace",
                l_GridData[i]
              );
              SelectedWorkSpaceid = l_GridData[i].Code;
            }
          }
          if (
            SelectedWorkSpaceid === "" &&
            (workspaceid == null ||
              workspaceid == undefined ||
              workspaceid === "")
          ) {
            if (l_GridData.length > 0) {
              SetMySelectedWorkspace(l_GridData[0]);
              SelectedWorkSpaceid = l_GridData[0].Code;
              AppContext.SetMyWorkSpaces(l_GridData[0].MGWorkspaceID);
              AppContext.SetLocalStorageObject(
                "SelectedWorkSpace",
                l_GridData[0]
              );
            }
          }

          if (SelectedWorkSpaceid !== workspaceid) {
            Navigate("/dashboard/" + SelectedWorkSpaceid);
          }
        } else {
          Navigate("/workspace");
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setText(e.target.value);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const files = Array.from(e.dataTransfer.files);
    setUploadedFiles((prevFiles) => [...prevFiles, ...files]);
  };

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(e.target.files || []);
    setUploadedFiles((prevFiles) => [...prevFiles, ...files]);
  };

  const handleRemoveFile = (file: File) => {
    setUploadedFiles((prevFiles) => prevFiles.filter((f) => f !== file));
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLTextAreaElement>) => {
    const clipboardItems = e.clipboardData.items;
    for (let i = 0; i < clipboardItems.length; i++) {
      const item = clipboardItems[i];
      if (item.type.indexOf("image") !== -1) {
        const file = item.getAsFile();
        if (file) {
          setUploadedFiles((prevFiles) => [...prevFiles, file]);
        }
      }
    }
  };

  const renderFilePreview = (file: File) => {
    const fileType = file.type.split("/")[0];
    const fileName = file.name;

    if (fileType === "image") {
      return (
        <div className="file-preview-wrapper" key={file.name}>
          <img
            src={URL.createObjectURL(file)}
            alt="file-preview"
            className="file-preview"
          />
          <button
            className="remove-file-button"
            onClick={() => handleRemoveFile(file)}
          >
            ❌
          </button>
        </div>
      );
    }

    if (fileName.endsWith(".pdfx") || fileName.endsWith(".pdf") || (fileName.endsWith(".docx") || fileName.endsWith(".doc"))) {
      return (
        <div className="file-preview-wrapper">
          <div className="file-preview">
            {fileName.endsWith(".pdfx") || fileName.endsWith(".pdf") && (
              <img
                src="/images/pdfT.png"
                alt="pdf File Logo"
              />
            )}
            {fileName.endsWith(".doc") || fileName.endsWith(".docx") && (
              <img
                src="/images/docT.png"
                alt="pdf File Logo"
              />
            )}
            <span className="file-preview-name">{fileName}</span>
          </div>
          <button
            className="remove-file-button"
            onClick={() => handleRemoveFile(file)}
          >
            ❌
          </button>
        </div>
      );
    }

    if (fileName.endsWith(".xlsx") || fileName.endsWith(".xls") || (fileName.endsWith(".zipx") || fileName.endsWith(".zip"))) {
      return (
        <div className="file-preview-wrapper">
          <div className="file-preview">
            {fileName.endsWith(".xls") || fileName.endsWith(".xlsx") && (
              <img
                src="/images/excelT.png"
                alt="excel File Logo"
              />
            )}
            {fileName.endsWith(".zipx") || fileName.endsWith(".zip") && (
              <img
                src="/images/zipT.png"
                alt="zip File Logo"
              />
            )}
            <span className="file-preview-name">{fileName}</span>
          </div>
          <button
            className="remove-file-button"
            onClick={() => handleRemoveFile(file)}
          >
            ❌
          </button>
        </div>
      );
    }

    if (fileName.endsWith(".mp3") || fileName.endsWith(".mp4") || (fileName.endsWith(".txt") || fileName.endsWith("./"))) {
      return (
        <div className="file-preview-wrapper">
          <div className="file-preview">
            {fileName.endsWith(".mp3") || fileName.endsWith(".mp4") && (
              <img
                src="/images/playbuttonT.png"
                alt="media File Logo"
              />
            )}
            {fileName.endsWith("./") || fileName.endsWith(".txt") && (
              <img
                src="/images/otherfileT.png"
                alt="text File Logo"
              />
            )}
            <span className="file-preview-name">{fileName}</span>
          </div>
          <button
            className="remove-file-button"
            onClick={() => handleRemoveFile(file)}
          >
            ❌
          </button>
        </div>
      );
    }

    // if (fileName.endsWith(".docx") || fileName.endsWith(".doc")) {
    //   return (
    //     <div className="file-preview-wrapper">
    //       <div className="file-preview-logo">
    //         <img
    //           src="/images/docT.png"
    //           alt="Document File Logo"
    //         />
    //         <span className="file-preview-name">{fileName}</span>
    //       </div>
    //       <button
    //         className="remove-file-button"
    //         onClick={() => handleRemoveFile(file)}
    //       >
    //         ❌
    //       </button>
    //     </div>
    //   );
    // }

    // if (fileName.endsWith(".xlsx") || fileName.endsWith(".xls")) {
    //   return (
    //     <div className="file-preview-wrapper">
    //       <div className="file-preview-logo">
    //         <img
    //           src="/images/excelT.png"
    //           alt="xls File Logo"
    //         />
    //         <span className="file-preview-name">{fileName}</span>
    //       </div>
    //       <button
    //         className="remove-file-button"
    //         onClick={() => handleRemoveFile(file)}
    //       >
    //         ❌
    //       </button>
    //     </div>
    //   );
    // }

    // if (fileName.endsWith(".zipx") || fileName.endsWith(".zip")) {
    //   return (
    //     <div className="file-preview-wrapper">
    //       <div className="file-preview-logo">
    //         <img
    //           src="/images/zipT.png"
    //           alt="zip File Logo"
    //         />
    //         <span className="file-preview-name">{fileName}</span>
    //       </div>
    //       <button
    //         className="remove-file-button"
    //         onClick={() => handleRemoveFile(file)}
    //       >
    //         ❌
    //       </button>
    //     </div>
    //   );
    // }

    // if (fileName.endsWith(".mp3") || fileName.endsWith(".mp4")) {
    //   return (
    //     <div className="file-preview-wrapper">
    //       <div className="file-preview-logo">
    //         <img
    //           src="/images/playbuttonT.png"
    //           alt="play File Logo"
    //         />
    //         <span className="file-preview-name">{fileName}</span>
    //       </div>
    //       <button
    //         className="remove-file-button"
    //         onClick={() => handleRemoveFile(file)}
    //       >
    //         ❌
    //       </button>
    //     </div>
    //   );
    // }

    return (
      <div className="file-preview-wrapper other-preview file-preview">
        <img src="/icons/otherfileT.png" alt="File icon" className="file-preview" />
        <span className="file-preview-name">{fileName}</span>
        <button
          className="remove-file-button"
          onClick={() => handleRemoveFile(file)}
        >
          ❌
        </button>
      </div>
    );
  };

  return (
    <div className="dashboard">
      <h1 className="dashboard-title">Dashboard</h1>
      <Link to={`/comm/${MySelectedWorkspace.Code}`}>
        <div className="workspace-info">
          <div>Welcome --- {MySelectedWorkspace.Title}</div>
          <div>Work Space ID --- {MySelectedWorkspace.MGWorkspaceID}</div>
        </div>
      </Link>

      {/* <div
        className="textarea-wrapper"
        onDrop={handleDrop}
        onDragOver={(e) => e.preventDefault()}
      >
        <div className="textarea">
          {uploadedFiles.length > 0 && (
            <div className="uploaded-files-preview">
              {uploadedFiles.map((file, index) => (
                <div key={index} className="uploaded-file-item">
                  {renderFilePreview(file)}
                </div>
              ))}
            </div>
          )}
          <textarea
            ref={textAreaRef}
            className="custom-textarea"
            value={text}
            onChange={handleTextChange}
            placeholder="Type your message here..."
            rows={5}
            onPaste={handlePaste}
          />
          <label htmlFor="fileInput" className="upload-button">
            📎 Attach File
          </label>
          <button className="sendbtn">send</button>
        </div>
        <div className="file-attachment-container">
          <input
            type="file"
            id="fileInput"
            accept="image/*, .pdf, .doc, .docx, .txt, .zip, .mp4, .jpg, .jpeg, .png, .xlsx"
            multiple
            onChange={handleFileUpload}
            style={{ display: 'none' }}
          />
        </div>
      </div> */}
    </div>
  );
}

export default Dashboard;