import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import AppContext from "../../Context/Context";
import axiosAuth from "../../axios/Interceptors";
import moment from "moment";
import { GetSelectedIDsFromListWithComma } from "../../Shared/Utility";
import StatusView from "./StatusView";
import ProjectView from "./ProjectView";
import EventDetailsView from "./EventDetailsView";
import TasksComm from "./TasksComm";
import ResourceView from "./ResourceView";
import ElapsedTime from "../../App/ElapsedTime";
import LoadingAnimation from "../../LoadingAnimation/LoadingAnimation";
import { Modal } from "react-bootstrap";
import Notification from "../../App/Notification";
import { useGlobalState } from "../../Context/GlobalStateContext";
function ProjectList() {
  const globalState = useGlobalState();
  const [loadingState, setLoadingState] = useState(false);
  const [addNewModal, setAddNewModal] = useState(false);
  const [projectsListData, setProjectsListData] = useState<any>([]);

  const [addNewObj, setAddNewObj] = useState({ Name: "" });

  useEffect(() => {
    getProjectListData();
  }, []);

  function getProjectListData() {
    let object = {} as any;
    setLoadingState(true);
    axiosAuth
      .post(
        AppContext.AppBaseURL +
          "api/MGProject/MGProjectGetCompleteGridDataCustom",
        object
      )
      .then((response: any) => {
        if (response.data.Status === 1) {
          let result = response.data.Data;
          if (result != null && result.length > 0) {
            setProjectsListData(result[0]);
          }
        }
        setLoadingState(false);
      })
      .catch((error: any) => {
        console.error("There was an error!", error);
      });
  }

  function updateMGProjectStatus(mgprojectid: any, objectstatus: any) {
    let object = {} as any;
    object.mgprojectid = mgprojectid;
    object.objectstatus = objectstatus;
    axiosAuth
      .post(
        AppContext.AppBaseURL + "api/MGProject/UpdateMGProjectStatus",
        object
      )
      .then((response: any) => {
        if (response.data.Status === 1) {
          let result = response.data.Data;
          getProjectListData();
        }
      })
      .catch((error: any) => {
        console.error("There was an error!", error);
      });
  }

  function NotificationCallBack() {
    globalState.setNotificationObject({
      ShowPopup: false,
      Heading: "",
      Message: "",
    });
  }

  function checkValidation() {
    if (addNewObj.Name == "") {
      globalState.setNotificationObject({
        ShowPopup: true,
        Heading: "Alert",
        Message: "Please enter project name",
      });
    } else {
      addNewMGProject();
    }
  }

  function addNewMGProject() {
    let object = {} as any;
    object.name = addNewObj.Name;
    object.shortname = addNewObj.Name;
    object.abbreviation = addNewObj.Name;
    object.shortdescription = addNewObj.Name;
    object.description = addNewObj.Name;
    object.mgworkspaceid = 1;
    object.mgprojecttypeid = 2;
    object.status = 4;
    object.objectstatus = 1;
    object.ispotential = 0;
    object.ptorderby =
      projectsListData && projectsListData.length > 0
        ? projectsListData[projectsListData.length - 1].PTOrderBy + 1
        : 1;
    setLoadingState(true);
    axiosAuth
      .post(AppContext.AppBaseURL + "api/MGProject/MGProjectSaveCustom", object)
      .then((response: any) => {
        if (response.data.Status === 1) {
          let result = response.data.Data;
          setAddNewModal(false);
          getProjectListData();
        }
      })
      .catch((error: any) => {
        console.error("There was an error!", error);
        setLoadingState(true);
      });
  }

  return (
    <>
      {loadingState && <LoadingAnimation />}

      <Notification
        NotificationObject={globalState.NotificationObject}
        NotificationCallBack={NotificationCallBack}
      />

      <div className="reuse-btn">
        <a
          onClick={() => {
            addNewObj.Name = "";
            setAddNewModal(true);
          }}
        >
          Add New Project
        </a>
      </div>

      <div className="p-all10">
        <div className="overflow-auto">
          <table id="tblExportGrid" className="reuse-table">
            <thead>
              <tr>
                <th className="sticky-header">Name</th>
                <th className="sticky-header col-xxs">Active</th>
              </tr>
            </thead>
            <tbody>
              {projectsListData &&
                projectsListData.map((Item: any, index: any) => (
                  <tr key={index}>
                    <td>{Item.Name}</td>
                    <td className="text-center col-xxs">
                      <input
                        className="input-check"
                        type="checkbox"
                        checked={Item.ObjectStatus == 1 ? true : false}
                        onChange={(e: any) =>
                          updateMGProjectStatus(
                            Item.MGProjectID,
                            e.target.checked == true ? 1 : 0
                          )
                        }
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>

      <Modal show={addNewModal} onHide={() => setAddNewModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Project</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="grid-d flex-gap15">
            <div className="grid-d flex-gap3 p-relative">
              <span className="bold-font txt-grey">Name</span>
              <input
                className="input--type__text sup-input"
                value={addNewObj.Name}
                onChange={(e) =>
                  setAddNewObj((prevState: any) => ({
                    ...prevState,
                    Name: e.target.value,
                  }))
                }
                type="text"
              />
            </div>
          </div>
          <div className="modal--button del--modal__btn">
            <a onClick={() => checkValidation()}>Save</a>
            <a className="cancel" onClick={() => setAddNewModal(false)}>
              Cancel
            </a>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ProjectList;
